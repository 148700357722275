import {
  CollectionHexLinkId,
  CollectionId,
  HexId,
  ProjectRole,
} from "@hex/common";
import { sortBy } from "lodash";
import React, { useCallback, useMemo } from "react";
import styled from "styled-components";

import { HexButton } from "../../hex-components";
import { SafeCollectionHexLinkFragment } from "../../mutations/collections.generated";
import { MoreMenuIcon } from "../icons/CustomIcons";

import { CollectionRow } from "./CollectionRow";
import { getCollapsedList } from "./utils";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const StyledMoreMenuIcon = styled(MoreMenuIcon)`
  transform: rotate(90deg);
`;

const StyledHeading = styled.div`
  padding: 15px 16px 8px;

  color: ${({ theme }) => theme.fontColor.MUTED};
  font-size: ${({ theme }) => theme.fontSize.SMALL};
`;

const CollectionList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const CollapseContainer = styled.div`
  display: flex;
  align-items: left;
  padding: 4px 8px;
`;

interface CollectionPermissionsListProps {
  collectionHexLinks: readonly SafeCollectionHexLinkFragment[];
  isComponent: boolean;
  // ensure the org role allows for the user to share Hexes.
  canShareHexes?: boolean;
  maxGrantableRole: ProjectRole | null;
  hexId: HexId;
  onRemoveCollection: (
    collectionId: CollectionId,
    collectionHexLinkId: CollectionHexLinkId,
  ) => Promise<void>;
  isSectionCollapsed: boolean;
  setIsSectionCollapsed: (isCollapsed: boolean) => void;
}

export const CollectionPermissionsList: React.ComponentType<CollectionPermissionsListProps> =
  React.memo(function CollectionPermissionsList({
    canShareHexes,
    collectionHexLinks,
    hexId,
    isComponent,
    isSectionCollapsed: collapsedCollections,
    maxGrantableRole,
    onRemoveCollection,
    setIsSectionCollapsed: setCollapsedCollections,
  }: CollectionPermissionsListProps) {
    const onSetCollapsedCollectionsCallback = useCallback(() => {
      setCollapsedCollections(false);
    }, [setCollapsedCollections]);

    const {
      collapseInfo: { collapsedCount, lastItem },
      displayedCollections,
    } = useMemo(() => {
      const sortedCollections = sortBy(
        collectionHexLinks,
        (link) => link.collection.name,
      );

      const { collapseInfo, displayedItems } = getCollapsedList({
        items: sortedCollections,
        isCollapsed: collapsedCollections,
      });
      return { displayedCollections: displayedItems, collapseInfo };
    }, [collapsedCollections, collectionHexLinks]);

    if (displayedCollections.length === 0) {
      return null;
    }

    return (
      <Container>
        <StyledHeading>Collections with additional access:</StyledHeading>
        <CollectionList>
          {displayedCollections.map((collectionHexLink) => {
            return (
              <CollectionRow
                key={collectionHexLink.id}
                canShareHexes={canShareHexes}
                collectionHexLink={collectionHexLink}
                hexId={hexId}
                isComponent={isComponent}
                maxGrantableRole={maxGrantableRole}
                onRemoveCollection={onRemoveCollection}
              />
            );
          })}
          {collapsedCount > 0 && lastItem && (
            <>
              <CollapseContainer>
                <HexButton
                  disabled={!collapsedCollections}
                  icon={<StyledMoreMenuIcon />}
                  minimal={true}
                  small={true}
                  text={`${collapsedCount} more collections`}
                  onClick={onSetCollapsedCollectionsCallback}
                />
              </CollapseContainer>
              <CollectionRow
                key={lastItem.id}
                canShareHexes={canShareHexes}
                collectionHexLink={lastItem}
                hexId={hexId}
                isComponent={isComponent}
                maxGrantableRole={maxGrantableRole}
                onRemoveCollection={onRemoveCollection}
              />
            </>
          )}
        </CollectionList>
      </Container>
    );
  });
