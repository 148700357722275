import React, { ReactNode, useCallback } from "react";
import styled from "styled-components";

import { HexSwitch } from "../../hex-components";

interface RoleMenuItemToggleProps {
  title: ReactNode;
  onToggle: (enabled: boolean) => void;
  enabled: boolean;
  "data-cy"?: string;
}

const ToggleDiv = styled.div`
  display: flex;
  width: fit-content;
`;

export const RoleMenuItemToggle: React.ComponentType<RoleMenuItemToggleProps> =
  React.memo(function RoleMenuItemToggle({
    "data-cy": dataCy,
    enabled,
    onToggle,
    title,
  }: RoleMenuItemToggleProps) {
    const toggleCallback = useCallback(
      (evt: React.FormEvent<HTMLInputElement>) => {
        onToggle(evt.currentTarget.checked);
      },
      [onToggle],
    );

    const handleClick = useCallback(
      (evt: React.MouseEvent<HTMLInputElement>) => {
        evt.stopPropagation();
      },
      [],
    );
    return (
      <ToggleDiv onClick={handleClick}>
        <HexSwitch
          checked={enabled}
          data-cy={dataCy}
          inline={true}
          small={true}
          onChange={toggleCallback}
        >
          {title}
        </HexSwitch>
      </ToggleDiv>
    );
  });
