import { Icon, Intent } from "@blueprintjs/core";
import {
  CollectionRole,
  DOCS_LINKS,
  GroupId,
  OrgRole,
  ProjectRole,
  UserId,
  guardNever,
  humanReadableOrgRole,
  humanReadableProjectRole,
  isOrgRoleSuperset,
} from "@hex/common";
import React, { ReactNode } from "react";
import styled from "styled-components";

import { HexCleanLink, HexTooltip, Txt } from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser.js";
import {
  PermissionListAppUserReasonFragment,
  PermissionListAssetAccessFragment,
  PermissionListDenialReasonFragment,
  PermissionListViewerReasonFragment,
} from "../../mutations/permissions.generated";
import { Routes, WorkspaceSettingsTabs } from "../../route/routes.js";
import { ContactAnAdmin } from "../common/ContactAnAdmin";
import { DocsLink } from "../common/DocsLink.js";
import { GroupsIcon, WarningIcon } from "../icons/CustomIcons";
import { Avatar } from "../user/Avatar";
import { UserAvatar } from "../user/UserAvatar";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 6px 16px;
  transition: background-color
    ${({ theme }) => `${theme.animation.duration} ${theme.animation.easing}`};

  &:hover {
    background-color: ${({ theme }) => theme.hoverColor};
  }
`;

const UserErrorDiv = styled.div`
  flex: none;
  width: 28px;
`;

const UserDiv = styled.div`
  display: flex;
  flex-grow: 1;
  gap: 8px;
  align-items: center;
  min-width: 0;
  height: 30px;
  padding-right: 10px;
`;

const UserInfoDiv = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const UserRoleRowRightDiv = styled.div`
  display: flex;
  align-items: center;
`;

const RoleDiv = styled.div`
  flex-grow: 1;
`;

interface PermissionRowItemProps {
  minimal?: boolean;
  avatar: ReactNode;
  name: string;
  label: string;
  isOwner?: boolean;
}

export const PermissionRowItem: React.ComponentType<PermissionRowItemProps> =
  React.memo(function PermissionRowItem({
    avatar,
    isOwner,
    label,
    minimal = false,
    name,
  }) {
    return (
      <UserDiv>
        {avatar}
        <UserInfoDiv>
          <HexTooltip content={label} disabled={!label} position="bottom">
            <Txt ellipsize={true} fontSize={minimal ? "small" : undefined}>
              {name}
              {isOwner && (
                <>
                  {" "}
                  <Txt fontColor="muted">(Owner)</Txt>
                </>
              )}
            </Txt>
          </HexTooltip>
        </UserInfoDiv>
      </UserDiv>
    );
  });

export type PermissionRowData = PermissionsRowUserData | PermissionsGroupData;

export type BasePermissionRowData = {
  role: ProjectRole | CollectionRole;
};

export type PermissionsRowUserData = {
  dataType: "user";
  id: UserId;
  /**
   * If this user is the current Project Owner.
   * This is helpful to determine when or if downgrading roles is allowed for the user.
   */
  isOwner?: boolean;
  name?: string | null;
  email: string;
  active?: boolean;
  orgRole: OrgRole;
  imageUrl?: string;
  viewerReason?: PermissionListViewerReasonFragment | null;
  appUserReason?: PermissionListAppUserReasonFragment | null;
  denialReason?: PermissionListDenialReasonFragment | null;
} & BasePermissionRowData;

export type PermissionsGroupData = {
  dataType: "group";
  id: GroupId;
  name: string;
} & BasePermissionRowData;

export interface PermissionRowProps {
  data: PermissionRowData;
  isComponent?: boolean;
  explorerRoleCanViewChange: boolean;
  rolePicker?: ReactNode;
  minimal?: boolean;
  className?: string;
}

export const PermissionRow: React.ComponentType<PermissionRowProps> =
  React.memo(function PermissionRow({
    className,
    data,
    explorerRoleCanViewChange,
    isComponent = false,
    minimal = false,
    rolePicker,
  }) {
    const currentUser = useCurrentUser();
    let warningMessage: JSX.Element | string | undefined = undefined;

    if (data.dataType === "user") {
      const userLabel = data.name ?? data.email;
      const isCurrentUserAdmin =
        currentUser != null &&
        isOrgRoleSuperset(currentUser?.orgRole, OrgRole.ADMIN);

      if (data.denialReason != null) {
        switch (data.denialReason.__typename) {
          case "AssetAccess":
            warningMessage = generatedMissingAssetWarning({
              action: "cannot access project",
              assetAccess: data.denialReason,
              isCurrentUserAdmin,
              userLabel,
            });
            break;
          case "MissingProjectRole": // this denial reason is not relevant for the permission list
            break;
          default:
            warningMessage = `${userLabel} cannot access project.`;
            guardNever(
              data.denialReason,
              (data.denialReason as { __typename: string }).__typename,
            );
        }
      } else if (data.appUserReason != null) {
        const downgradedToLabel = (
          <>
            has been downgraded to{" "}
            <strong>
              {humanReadableProjectRole(
                ProjectRole.APP_USER,
                isComponent,
                explorerRoleCanViewChange,
              )}
            </strong>{" "}
            access
          </>
        );
        switch (data.appUserReason.__typename) {
          case "MissingOrgRole":
            warningMessage = generateMissingOrgRoleWarning({
              userLabel,
              isCurrentUserAdmin,
              downgradedToLabel,
              currentOrgRole: data.appUserReason.currentOrgRole,
              neededOrgRole: data.appUserReason.neededOrgRole,
            });
            break;
          case "MissingProjectRole": // this app user reason is not relevant for the permission list
            break;
          default:
            warningMessage = (
              <>
                {userLabel} {downgradedToLabel}.
              </>
            );
            guardNever(
              data.appUserReason,
              (data.appUserReason as { __typename: string }).__typename,
            );
        }
      } else if (data.viewerReason != null) {
        const downgradedToLabel = (
          <>
            has been downgraded to{" "}
            <strong>
              {humanReadableProjectRole(
                ProjectRole.VIEWER,
                isComponent,
                explorerRoleCanViewChange,
              )}
            </strong>{" "}
            access
          </>
        );
        switch (data.viewerReason.__typename) {
          case "AssetAccess":
            warningMessage = generatedMissingAssetWarning({
              userLabel,
              isCurrentUserAdmin,
              action: downgradedToLabel,
              assetAccess: data.viewerReason,
            });
            break;
          case "MissingOrgRole": {
            warningMessage = generateMissingOrgRoleWarning({
              userLabel,
              isCurrentUserAdmin,
              downgradedToLabel,
              currentOrgRole: data.viewerReason.currentOrgRole,
              neededOrgRole: data.viewerReason.neededOrgRole,
            });
            break;
          }
          case "OAuthCredsNotShared":
            warningMessage = (
              <>
                {userLabel} {downgradedToLabel} due to using an OAuth data
                connection without shared credentials.{" "}
                <DocsLink to={DOCS_LINKS.SnowflakeOAuth}>Learn more.</DocsLink>
              </>
            );
            break;
          case "OAuthCollaborationNonSessionOwner":
            break;
          case "MissingProjectRole": // this viewer reason is not relevant for the permission list
          case "NonDraftVersion": // we handle this case with a nav bar warning
          case "Archived": // we handle this case with a nav bar warning
          case "Trashed": // we handle this case with a nav bar warning
            break;
          default:
            warningMessage = (
              <>
                {userLabel} {downgradedToLabel}.
              </>
            );
            guardNever(
              data.viewerReason,
              (data.viewerReason as { __typename: string }).__typename,
            );
        }
      }
    }

    return (
      <Container className={className}>
        {data.dataType === "user" && data.active !== true && (
          <HexTooltip
            content="This user's account has been deactivated."
            placement="top"
          >
            <UserErrorDiv>
              <Icon icon="error" intent="warning" />
            </UserErrorDiv>
          </HexTooltip>
        )}
        {data.dataType === "user" ? (
          <PermissionRowItem
            avatar={
              <UserAvatar
                active={true}
                email={data.email}
                imageUrl={data.imageUrl ?? undefined}
                name={data.name ?? undefined}
                size={30}
              />
            }
            isOwner={data.isOwner}
            label={data.email}
            minimal={minimal}
            name={data.name || data.email}
          />
        ) : (
          <PermissionRowItem
            avatar={
              <Avatar
                active={true}
                altText={data.name}
                size={30}
                text={<GroupsIcon />}
              />
            }
            label=""
            minimal={minimal}
            name={data.name}
          />
        )}
        <UserRoleRowRightDiv>
          {warningMessage && (
            <HexTooltip content={warningMessage} interactionKind="hover">
              <WarningIcon intent={Intent.WARNING} />
            </HexTooltip>
          )}
          <RoleDiv>{rolePicker}</RoleDiv>
        </UserRoleRowRightDiv>
      </Container>
    );
  });

function generateMissingOrgRoleWarning({
  currentOrgRole,
  downgradedToLabel,
  isCurrentUserAdmin,
  neededOrgRole,
  userLabel,
}: {
  downgradedToLabel: ReactNode;
  userLabel: ReactNode;
  neededOrgRole: OrgRole;
  currentOrgRole: OrgRole;
  isCurrentUserAdmin: boolean;
}): JSX.Element {
  const promotionPrompt = isCurrentUserAdmin ? (
    <HexCleanLink to={Routes.SETTINGS.getUrl({ subView: "users" })}>
      Upgrade
    </HexCleanLink>
  ) : (
    <>
      <ContactAnAdmin /> to upgrade
    </>
  );

  return (
    <>
      {userLabel} {downgradedToLabel} as they have the{" "}
      <strong>{humanReadableOrgRole(currentOrgRole)}</strong> role.
      <br /> {promotionPrompt} this user to an{" "}
      <strong>{humanReadableOrgRole(neededOrgRole)}</strong> role.
    </>
  );
}

function generatedMissingAssetWarning({
  action,
  assetAccess: {
    dataConnectionsMissingAccess,
    filesMissingAccess,
    hasAccessToSharedDataConnections,
    hasAccessToSharedFiles,
    hasAccessToSharedPackages,
    hasAccessToSharedSecrets,
    hasAccessToSharedSyncRepositories,
    secretsMissingAccess,
    sharedPackagesMissingAccess,
    syncRepositoriesMissingAccess,
  },
  isCurrentUserAdmin,
  userLabel,
}: {
  userLabel: ReactNode;
  isCurrentUserAdmin: boolean;
  assetAccess: PermissionListAssetAccessFragment;
  action: ReactNode;
}): JSX.Element {
  const missingConnections = dataConnectionsMissingAccess.map(
    (e) => e.connectionName,
  );

  const missingPackages = sharedPackagesMissingAccess.map((e) => e.repoName);
  const missingSecrets = secretsMissingAccess.map((e) => e.name);
  const missingRepostiories = syncRepositoriesMissingAccess.map(
    (e) => e.repoName,
  );
  const missingFiles = filesMissingAccess.map((e) => e.filename);

  const missingAssetNames = missingConnections
    .concat(missingPackages)
    .concat(missingSecrets)
    .concat(missingRepostiories)
    .concat(missingFiles);

  const numberOfAssetTypesMissingAccess = [
    hasAccessToSharedDataConnections,
    hasAccessToSharedPackages,
    hasAccessToSharedSecrets,
    hasAccessToSharedSyncRepositories,
    hasAccessToSharedFiles,
  ].reduce((acc, hasAccess) => (!hasAccess ? acc + 1 : acc), 0);

  let assetType: string;
  let subView: WorkspaceSettingsTabs;

  if (numberOfAssetTypesMissingAccess > 1) {
    assetType = "assets";
    subView = "general";
  } else if (!hasAccessToSharedDataConnections) {
    assetType =
      dataConnectionsMissingAccess.length > 1
        ? "data connections"
        : "data connection";
    subView = "data-sources";
  } else if (!hasAccessToSharedPackages) {
    assetType = sharedPackagesMissingAccess.length > 1 ? "packages" : "package";
    subView = "integrations";
  } else if (!hasAccessToSharedSecrets) {
    assetType = secretsMissingAccess.length > 1 ? "secrets" : "secret";
    subView = "secrets";
  } else if (!hasAccessToSharedSyncRepositories) {
    assetType =
      syncRepositoriesMissingAccess.length > 1
        ? "sync repositories"
        : "sync repository";
    subView = "integrations";
  } else if (!hasAccessToSharedFiles) {
    assetType = filesMissingAccess.length > 1 ? "files" : "file";
    subView = "general";
  } else {
    assetType = "assets";
    subView = "general";
  }

  return (
    <>
      {userLabel} {action} due to missing permissions on shared {assetType}:{" "}
      <strong>{missingAssetNames.join(", ")}</strong>
      <br />{" "}
      {isCurrentUserAdmin ? (
        <>
          <HexCleanLink to={Routes.SETTINGS.getUrl({ subView })}>
            Update shared {assetType} permissions
          </HexCleanLink>{" "}
        </>
      ) : (
        <>
          <ContactAnAdmin /> to grant access.
        </>
      )}
    </>
  );
}
