import { Classes, Intent } from "@blueprintjs/core";
import {
  HexId,
  HexType,
  OrgRole,
  ProjectRole,
  isOrgRoleSuperset,
} from "@hex/common";
import { noop } from "lodash";
import React, { useCallback } from "react";
import { useHistory } from "react-router";

import {
  HexButton,
  HexCallout,
  HexDialog,
  HexMenuDivider,
  HexMenuItem,
  HexTooltip,
} from "../../hex-components";
import { useCurrentUser } from "../../hooks/me/useCurrentUser";
import { useToggleState } from "../../hooks/useToggleState";
import { useRemoveSelfFromHexMutation } from "../../mutations/permissions.generated";
import { Routes } from "../../route/routes";
import { useProjectContext } from "../../util/projectContext";
import { useHexFlag } from "../../util/useHexFlags.js";
import { ContactAnAdmin } from "../common/ContactAnAdmin";
import { useToaster } from "../common/Toasts";

import { PermissionRow, PermissionsRowUserData } from "./PermissionRow";
import { RoleDropdown } from "./RoleDropdown";

export interface CurrentUserPermissionRowProps {
  hexId: HexId;
  showOnlyOwnerWarning: boolean;
  permissionRowData: PermissionsRowUserData;
}

/**
 * Allows a user to remove themselves from a project.
 */
export const CurrentUserPermissionRow: React.ComponentType<CurrentUserPermissionRowProps> =
  React.memo(function CurrentUserPermissionRow({
    hexId,
    permissionRowData: data,
    showOnlyOwnerWarning,
  }) {
    const toaster = useToaster();
    const history = useHistory();
    const explorerRoleCanViewChange = useHexFlag(
      "explorer-role-can-view-change",
    );
    const currentUser = useCurrentUser();
    const { hexType } = useProjectContext();
    const hexTypeText = hexType === HexType.COMPONENT ? "component" : "project";
    const isAdmin = currentUser
      ? isOrgRoleSuperset(currentUser.orgRole, OrgRole.ADMIN)
      : false;

    const [removeSelfFromHex] = useRemoveSelfFromHexMutation();
    const removeSelf = useCallback(async () => {
      try {
        await removeSelfFromHex({
          variables: { hexId },
        });
        if (isAdmin) {
          location.reload();
        } else {
          Routes.push(history, Routes.HOME, {});
        }
      } catch (e) {
        toaster.show({
          message:
            e instanceof Error ? e.message : "Error occurred removing self",
          intent: Intent.DANGER,
        });
      }
    }, [removeSelfFromHex, hexId, isAdmin, history, toaster]);

    const [
      isRemoveSelfDialogOpen,
      _,
      { setFalse: onCloseRemoveSelfDialog, setTrue: openRemoveSelfDialog },
    ] = useToggleState(false);

    return (
      <>
        {isRemoveSelfDialogOpen && (
          <HexDialog
            isOpen={isRemoveSelfDialogOpen}
            title="Are you sure?"
            onClose={onCloseRemoveSelfDialog}
          >
            <div className={Classes.DIALOG_BODY}>
              {showOnlyOwnerWarning && !isAdmin && (
                <HexCallout
                  $noBackground={true}
                  css={{ marginBottom: 10 }}
                  intent={Intent.WARNING}
                >
                  You are the only owner of this project.
                </HexCallout>
              )}
              This will remove your permissions on the project
              {isAdmin ? (
                "."
              ) : (
                <>
                  , and you will lose access to the current page. If you need to
                  regain access after removing yourself, you must&nbsp;
                  <ContactAnAdmin />.
                </>
              )}
            </div>
            <div
              className={Classes.DIALOG_FOOTER}
              css={`
                display: flex;
                justify-content: flex-end;
                gap: 10px;
              `}
            >
              <HexButton onClick={onCloseRemoveSelfDialog}>Cancel</HexButton>
              <HexButton intent={Intent.DANGER} onClick={removeSelf}>
                Confirm
              </HexButton>
            </div>
          </HexDialog>
        )}
        <PermissionRow
          key={data.id}
          data={data}
          explorerRoleCanViewChange={explorerRoleCanViewChange}
          rolePicker={
            <RoleDropdown
              additionalActions={
                <>
                  <HexMenuDivider />
                  <HexTooltip
                    content={`As the ${hexTypeText} owner, you must first transfer ownership before removing yourself from the ${hexTypeText}.`}
                    disabled={!data.isOwner}
                    targetTagName="div"
                  >
                    <HexMenuItem
                      disabled={data.isOwner}
                      intent={Intent.DANGER}
                      text="Remove self"
                      onClick={openRemoveSelfDialog}
                    />
                  </HexTooltip>
                </>
              }
              explorerRoleCanViewChange={explorerRoleCanViewChange}
              isComponent={hexType === HexType.COMPONENT}
              maxRole={data.role as ProjectRole}
              minRole={data.role as ProjectRole}
              selectedRole={data.role as ProjectRole}
              onSelectRole={noop}
            />
          }
        />
      </>
    );
  });
